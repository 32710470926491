<template>
  <b-modal
    visible
    scrollable
    centered
    title="Объединение пациентов"
    hide-footer
    size="lg"
    no-close-on-backdrop
    @hidden="onClose"
    @ok="onClose"
  >
    <div
      class="container policy-container"
    >
      <div class="clinics-form-fields d-flex align-items-center">
        <base-input
          v-model="$v.personIdTo.$model"
          :error="$v.personIdTo.$error"
          :errors="errorsValidation.personIdTo"
          :input-style="{
            padding: '11px 15px 12px',
            resize: 'none',
            backgroundColor: '#ffffff',
          }"
          class="crm-form-field mr-0"
          label="Куда переносим данные"
          placeholder="Введите id учетной записи"
        />

        <b-icon
          class="mx-4 transfer-arrow"
          icon="arrow-left"
          font-scale="1.5"
          animation="cylon"
        />

        <base-input
          v-model="$v.personIdFrom.$model"
          :error="$v.personIdFrom.$error"
          :errors="errorsValidation.personIdFrom"
          :input-style="{
            padding: '11px 15px 12px',
            resize: 'none',
            backgroundColor: '#ffffff',
          }"
          class="crm-form-field"
          label="Откуда переносим данные"
          placeholder="Введите id учетной записи"
        />
      </div>
      <div
        class="crm-description"
        :class="{'mt-5': this.$v.$error}"
      >
        В основную учетную запись перенесутся все данные <br> из дочерней учетной записи
      </div>

      <div>
        <b-form-group
          label="Комментарий"
          label-class="required"
          :state="!$v.comment.$error"
          :invalid-feedback="errorsValidation.comment[0]"
        >
          <b-form-textarea
            v-model.trim="$v.comment.$model"
            placeholder="Введите текст"
            rows="1"
            max-rows="3"
            size="sm"
          />
        </b-form-group>
      </div>

      <div class="crm-wrapper-buttons">
        <b-button
          variant="danger"
          :type="$const.PRIMARY_BUTTON"
          class="crm-button mr-5 px-3"
          @click="onClose"
        >
          Отменить
        </b-button>

        <b-button
          variant="primary"
          :type="$const.PRIMARY_BUTTON"
          :loading="isLoading"
          class="crm-button"
          :disabled="isDisabled"
          @click="onClickTransfer"
        >
          Объединить
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Bus from '@/eventBus';

import { showValidationErrorCustomMessage } from '@/helpers/messages';

import { required } from 'vuelidate/lib/validators';

import {
  BaseInput,
} from '@/components/base';

export default {
  name: 'PatientTransferModal',
  components: {
    BaseInput,
  },
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      personIdTo: null,
      personIdFrom: null,
      comment: '',
    };
  },
  validations() {
    return {
      comment: {
        required,
      },
      personIdTo: {
        required,
      },
      personIdFrom: {
        required,
      },
    };
  },
  computed: {
    errorsValidation() {
      const errors = {};

      errors.comment = [];
      if (!this.$v.comment.required) {
        errors.comment.push('Поле "Комментарий" обязательно для заполнения');
      }
      errors.personIdTo = [];
      if (!this.$v.personIdTo.required) {
        errors.personIdTo.push('Поле "Куда переносим данные" обязательно для заполнения');
      }
      errors.personIdFrom = [];
      if (!this.$v.personIdFrom.required) {
        errors.personIdFrom.push('Поле "Откуда переносим данные" обязательно для заполнения');
      }

      return errors;
    },
    isDisabled() {
      return !this.comment.length;
    },
  },
  methods: {
    onClose() {
      this.$emit('input', false);
    },
    async onClickTransfer() {
      this.$v.$touch();

      if (this.$v.$error) {
        console.log(this.$v.error);
        showValidationErrorCustomMessage('Проверьте заполнены ли все поля');
        return;
      }

      try {
        this.isLoading = true;

        await this.$store.dispatch(this.$types.PATIENT_TRANSFER, {
          personIdTo: this.personIdTo,
          personIdFrom: this.personIdFrom,
          comment: this.comment,
        });

        Bus.$emit('patients:update');
        this.onClose();
        this.afterDelete();
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.policy-container {
  flex-direction: column;
  align-items: center;
}
.crm-wrapper-buttons {
  margin-top: 35px;
}
.crm-description {
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  margin-top: -10px;
}
.clinics-form-fields {
  margin-top: 20px;
}

.transfer-arrow {
  margin-top: 40px;
}

::v-deep.form-field {
  .label {
    margin-bottom: 10px;
  }
}
</style>
